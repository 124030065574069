import React, { useState } from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
// import Hidden from "@material-ui/core/Hidden";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";
import Popover from "@material-ui/core/Popover";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, useMediaQuery } from "@material-ui/core";

import { Divider, Button } from "@material-ui/core";
import "./Footer1.css";

const useStyles = makeStyles((theme) => ({
  footer_content: {
    display: "flex",
    alignSelf: "center",

    flexDirection: "column",
    [theme.breakpoints.up(1250)]: {
      flexDirection: "row",
      gap: "3rem",
    },
  },
  languages: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  social_media: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footer_links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  container: {
    position: "relative",
    backgroundColor: "#f4eee2",
    padding: "5px 10px",
    paddingBottom: 30,
    paddingTop: 40,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 10px",
      paddingTop: 50,
    },
  },
  logoMascota: {
    width: 70,
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      width: 100,
    },
  },
  containerMascota: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      textAlign: "center",
    },
  },
  textUnanime: {
    padding: "6px 1px",
    display: "block",
    color: "#706e6c",
    fontSize: 11,
    fontFamily: "Roboto-Rel",
    minHeight: 48,
    width: "max-content",
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        color: "#706e6c",
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      padding: "6px 8px",
    },
  },

  divider: {
    width: "100%",
    color: "#b78a57",
    backgroundColor: "#b78a57",
    margin: "10px auto",
    [theme.breakpoints.up("md")]: {
      margin: "30px auto",
    },
  },

  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  containerLanguage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    color: "#424242",
  },
  botonIdioma: {
    color: "#424242",
    fontSize: 11,
    fontFamily: "Roboto-Rel",
    padding: 0,
    minHeight: 48,
    minWidth: 35,
  },
  botonIdiomaBold: {
    color: "#424242",
    fontSize: 11,
    fontFamily: "Roboto-Bold",
    padding: 0,
    minHeight: 48,
    minWidth: 35,
  },
  separador: {
    color: "#424242",
    fontSize: 11,
    padding: "6px 3px 6px 0px",
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
  },
  icono: {
    margin: "0 10px",
    color: "#a1a1a1",
    width: 30,
    height: 30,
  },
  icono2: {
    // border:"2px solid #a1a1a1",
    margin: "0 10px",
    color: "#a1a1a1",
    width: 25,
    height: 25,
  },
  paperQR: {
    backgroundColor: "#f4eee2",
    // marginTop:-50
  },
  iconoQR: {
    width: 300,
    height: 300,
  },
}));
const useStylesAddIcon = makeStyles((theme) => ({
  root: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // fontSize: "30px !important",
    // display: "inline-block",
    // padding: "0 15px",
    // background:"trasparent",
    // transform:"rotate(45deg)"
  },
  close: {
    width: 50,
    height: 50,
    opacity: 1,
    transition: "all 0.3s ease 0s",
    "&:hover": {
      height: 40,
      width: 40,
    },
    // -moz-transition: all 0.3s ease 0s;
    // -o-transition: all 0.3s ease 0s;
    // -ms-transition: all 0.3s ease 0s;
    // -webkit-transition: all 0.3s ease 0s;
    // transition: "all 0.3s ease 0s"
  },
}));
const AddIcon = () => {
  const classes = useStylesAddIcon();
  return (
    <div className={classes.root}>
      <img
        alt={"close icon"}
        src={require("../assets/menu/close-icon.svg")}
        className={classes.close}
      />
    </div>
  );
};
const Footer = (props) => {
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLocal = Boolean(anchorEl);
  const id = openLocal ? "simple-popover" : undefined;
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 1250,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} className={classes.containerMascota}>
          <Link className={classes.link} to={"/"} component={RouterLink}>
            <Button>
              <img
                width={70}
                height={30}
                className={classes.logoMascota}
                src={require("../assets/logo_sena_oro.png")}
                alt="imagen home"
              />
            </Button>
          </Link>
        </Grid>
        <Divider className={classes.divider} />

        <div className={classes.footer_content}>
          <div className={classes.social_media}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Link
                className={classes.containerIcon}
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/senawinery/"
              >
                <InstagramIcon
                  titleAccess="instagram"
                  className={classes.icono}
                />
              </Link>
              <Link
                className={classes.containerIcon}
                target="_blank"
                rel="noopener"
                href="https://weixin.qq.com/r/K0gzK6TE2vmlreBI9x05"
              >
                <img
                  alt={"wechat"}
                  onMouseEnter={handleHover}
                  src={require("../assets/menu/wechat_icon.jpg")}
                  className={classes.icono2}
                />
                <Popover
                  id={id}
                  open={openLocal}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  style={{ marginTop: -100 }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                >
                  <Zoom in={true}>
                    <Paper elevation={4} className={classes.paperQR}>
                      <img
                        alt={"wechat"}
                        src={require("../assets/menu/wechat.jpg")}
                        className={classes.iconoQR}
                      />
                    </Paper>
                  </Zoom>
                </Popover>
              </Link>

              <Link
                className={classes.containerIcon}
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/channel/UC1q_v1g53IQA5a9sQmA7N7w/featured"
              >
                <YouTubeIcon titleAccess="youtube" className={classes.icono} />
              </Link>
            </div>
          </div>

          <div className={classes.footer_links}>
            {matches ? (
              <>
                {" "}
                <Link
                  className={classes.link}
                  to={"/Authentify-Your-Bottle"}
                  component={RouterLink}
                >
                  <Button
                    className={classes.textUnanime}
                    style={{ margin: "auto", padding: "0 6px" }}
                  >
                    {t("header.footer.auth")}
                  </Button>
                </Link>
                {/* <Button
                  onClick={() => {
                    console.log("click");
                    setOpen(true);
                  }}
                  className={classes.textUnanime}
                  style={{ margin: "auto", padding: "0 6px" }}
                >
                  {t("header.footer.auth")}
                </Button> */}
                <Typography
                  // className={classes.textUnanime}
                  style={{
                    color: "#706e6c",
                    fontSize: 11,
                    padding: "6px 3px 6px 0px",
                    fontFamily: "Rel-Bold",
                  }}
                >
                  |
                </Typography>
                <Link
                  className={classes.link}
                  to={"/Find-A-Reseller"}
                  component={RouterLink}
                >
                  <Button
                    className={classes.textUnanime}
                    style={{ margin: "auto", padding: "0 6px" }}
                  >
                    {t("header.footer.reseller")}
                  </Button>
                </Link>
                <Typography
                  // className={classes.textUnanime}
                  style={{
                    color: "#706e6c",
                    fontSize: 11,
                    padding: "6px 0px 6px 3px",
                    fontFamily: "Rel-Bold",
                  }}
                >
                  |
                </Typography>
                <Link
                  className={classes.link}
                  to={"/Contact"}
                  component={RouterLink}
                >
                  <Button
                    className={classes.textUnanime}
                    style={{ margin: "auto", padding: "0 6px" }}
                  >
                    {t("header.footer.contacto")}
                  </Button>
                </Link>
                {(t("idioma") === "ENG" || t("idioma") === "ESP") && (
                  <>
                    <Typography
                      // className={classes.textUnanime}
                      style={{
                        color: "#706e6c",
                        fontSize: 11,
                        padding: "6px 0px 6px 3px",
                        fontFamily: "Rel-Bold",
                      }}
                    >
                      |
                    </Typography>{" "}
                    <Link
                      className={classes.link}
                      to={`/pdf/sustainability_${t("idioma")
                        .slice(0, 2)
                        .toLocaleLowerCase()}.pdf`}
                      target="_blank"
                      rel="noreferrer"
                      component={RouterLink}
                    >
                      <Button
                        className={classes.textUnanime}
                        style={{ padding: "0 6px" }}
                      >
                        {t("header.footer.sustainability")}
                      </Button>
                    </Link>
                  </>
                )}
              </>
            ) : (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    className={classes.link}
                    to={"/Authentify-Your-Bottle"}
                    component={RouterLink}
                  >
                    <Button
                      className={classes.textUnanime}
                      style={{ margin: "auto", padding: "0 6px" }}
                    >
                      {t("header.footer.auth")}
                    </Button>
                  </Link>
                  {/* <Button
                    onClick={() => setOpen(true)}
                    className={classes.textUnanime}
                    style={{ margin: "auto", padding: "0 6px" }}
                  >
                    {t("header.footer.auth")}
                  </Button> */}
                  <Typography
                    // className={classes.textUnanime}
                    style={{
                      color: "#706e6c",
                      fontSize: 11,
                      padding: "6px 3px 6px 0px",
                      fontFamily: "Rel-Bold",
                    }}
                  >
                    |
                  </Typography>
                  <Link
                    className={classes.link}
                    to={"/Find-A-Reseller"}
                    component={RouterLink}
                  >
                    <Button
                      className={classes.textUnanime}
                      style={{ margin: "auto", padding: "0 6px" }}
                    >
                      {t("header.footer.reseller")}
                    </Button>
                  </Link>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    className={classes.link}
                    to={"/Contact"}
                    component={RouterLink}
                  >
                    <Button
                      className={classes.textUnanime}
                      style={{ margin: "auto", padding: "0 6px" }}
                    >
                      {t("header.footer.contacto")}
                    </Button>
                  </Link>
                  {(t("idioma") === "ENG" || t("idioma") === "ESP") && (
                    <>
                      <Typography
                        // className={classes.textUnanime}
                        style={{
                          color: "#706e6c",
                          fontSize: 11,
                          padding: "6px 0px 6px 3px",
                          fontFamily: "Rel-Bold",
                        }}
                      >
                        |
                      </Typography>{" "}
                      <Link
                        className={classes.link}
                        to={`/pdf/sustainability_${t("idioma")
                          .slice(0, 2)
                          .toLocaleLowerCase()}.pdf`}
                        target="_blank"
                        rel="noreferrer"
                        component={RouterLink}
                      >
                        <Button
                          className={classes.textUnanime}
                          style={{ padding: "0 6px" }}
                        >
                          {t("header.footer.sustainability")}
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className={classes.languages}>
            <div className={classes.containerLanguage}>
              <Button
                onClick={() => handleClick("engv10")}
                className={
                  t("idioma") === "ENG"
                    ? classes.botonIdiomaBold
                    : classes.botonIdioma
                }
              >
                EN
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("chiv10")}
                className={
                  t("idioma") === "CHI"
                    ? classes.botonIdiomaBold
                    : classes.botonIdioma
                }
              >
                中文
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("espv10")}
                className={
                  t("idioma") === "ESP"
                    ? classes.botonIdiomaBold
                    : classes.botonIdioma
                }
              >
                ES
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("japv10")}
                className={
                  t("idioma") === "JAP"
                    ? classes.botonIdiomaBold
                    : classes.botonIdioma
                }
              >
                日本 語
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <div className={"close-icon"} onClick={() => setOpen(false)}>
            <AddIcon />
          </div>
          <Typography gutterBottom className="text-modal">
            {t("idioma") === "ESP" ? (
              <React.Fragment>
                Nos encontramos haciendo actualizaciones.
                <br /> Para consultas sobre Prooftag por favor
                <br /> enviar email a{" "}
              </React.Fragment>
            ) : (
              <React.Fragment>
                We are currently making some updates.
                <br /> For Prooftag enquiries please
                <br /> contact{" "}
              </React.Fragment>
            )}
            <a href="mailto:constanza.parraguez@vfch.cl">
              constanza.parraguez@vfch.cl
            </a>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Footer.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Footer);
