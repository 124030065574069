import React, { useEffect,useState } from "react";
import { Transition } from "react-transition-group";

const FadeBotton = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    loader();// eslint-disable-next-line
  }, [props.open]);
  const loader = () => {
    if (props.type === "sticky") {
      setOpen(
        props.open === true
          ? open === true
            ? true
            : true
          : open === true
          ? true
          : false
      );
    } else {
      setOpen(props.open);
    }
  };
  const duration = props.duration < 0 ? props.duration : 1500;

  const defaultStyle = {
    transition: `opacity ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07),padding-top ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07)`,
    opacity: 0,
    paddingTop: props.paddingTop,
    transitionDelay: 1500,
  };

  const transitionStyles = {
    entering: { opacity: 1, paddingTop: 0 },
    entered: { opacity: 1, paddingTop: 0 },
    exiting: { opacity: 0, paddingTop: props.paddingTop },
    exited: { opacity: 0, paddingTop: props.paddingTop },
  };
  return (
    <Transition in={open} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};

export default FadeBotton;
