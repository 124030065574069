import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import loadable from "@loadable/component";
import Header from "./common/Header";
import Footer from "./common/Footer";
const Aconcagua = loadable(() =>
  import("./views/TheUniqueTerroir/Aconcagua/Aconcagua")
);
const ChileFirstIcon = loadable(() =>
  import("./views/TheStory/ChilleFirstIcon/ChileFirstIcon")
);
const Berlin = loadable(() => import("./views/TheStory/Berlin/Berlin"));
const FindReseller = loadable(() =>
  import("./views/FindReseller/FindReseller")
);
const Biodynamic = loadable(() =>
  import("./views/TheUniqueTerroir/Biodynamic/Biodynamic")
);
const Vineyard = loadable(() =>
  import("./views/TheUniqueTerroir/Vineyard/Vineyard")
);
const Hilltop = loadable(() =>
  import("./views/TheUniqueTerroir/Hilltop/Hilltop")
);
const Chile = loadable(() => import("./views/TheUniqueTerroir/Chile/Chile"));
const RocasDeSena = loadable(() => import("./views/Wines/WinesRocasDeSena"));
const WinesSena = loadable(() => import("./views/Wines/WinesSena"));
const Vertical = loadable(() => import("./views/TheStory/Vertical/Vertical"));
const Reaches = loadable(() => import("./views/TheStory/Reaches/Reaches"));
const Contact = loadable(() => import("./views/Contact/Contact"));
const International = loadable(() =>
  import("./views/TheStory/International/International")
);
const Decanter = loadable(() => import("./views/TheStory/Decanter/Decanter"));
const Legends = loadable(() => import("./views/TheStory/Legends/Legends"));
const Home = loadable(() => import("./views/TheStory/Home/Home"));
const Authentication = loadable(() =>
  import("./views/Authentication/Authentication")
);
const NewsHome = loadable(() => import("./views/News/NewsHome"));
const New5 = loadable(() => import("./views/News/New5"));
const New6 = loadable(() => import("./views/News/New6"));
const New7 = loadable(() => import("./views/News/New7"));
const New8 = loadable(() => import("./views/News/New8"));
const New9 = loadable(() => import("./views/News/New9"));

// const NewsHomeDraf = loadable(() => import("./views/News_draf/NewsHome"));
// const New8 = loadable(() => import("./views/News_draf/New8"));

const Legal = loadable(() => import("./views/Legal"));
const FormSena = loadable(() => import("./views/FormSena/Contact"));

function App() {
  if (window.location.pathname === "/Sena-Form") {
    return (
      <Router>
        <Route exact path="/Sena-Form" component={FormSena} />
      </Router>
    );
  }

  return (
    <Router>
      <Header />
      <Legal />
      <main style={{ minHeight: "100vh" }}>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route
            exact
            path="/Chile-a-viticultural-paradise"
            component={Chile}
          />
          <Route exact path="/The-Aconcagua-Valley" component={Aconcagua} />
          <Route exact path="/The-Vineyard" component={Vineyard} />
          <Route exact path="/On-The-Biodynamic-Path" component={Biodynamic} />
          <Route exact path="/The-Hilltop-Mirador" component={Hilltop} />

          <Route exact path="/Chile-first-icon" component={ChileFirstIcon} />
          <Route exact path="/The-Berlin-Tasting" component={Berlin} />
          <Route
            exact
            path="/Sena-Vertical-Tasting-Tour"
            component={Vertical}
          />
          <Route exact path="/Sena-Reaches-Perfection" component={Reaches} />
          <Route
            exact
            path="/Decanter-Man-Of-The-Year-2018"
            component={Decanter}
          />
          <Route exact path="/The-Legends-Of-Chile-Tour" component={Legends} />
          <Route
            exact
            path="/International-Ratings"
            component={International}
          />
          <Route
            exact
            path="/Authentify-Your-Bottle"
            component={Authentication}
          />

          <Route exact path="/Wines/sena/:year" component={WinesSena} />
          <Route
            exact
            path="/Wines/rocas_de_sena/:year"
            component={RocasDeSena}
          />
          <Route exact path="/Find-A-Reseller" component={FindReseller} />
          <Route exact path="/Contact" component={Contact} />

          <Route exact path="/News" component={NewsHome} />
          <Route
            exact
            path="/News/Sena-2018-Awarded-Perfect-100-Points"
            component={New5}
          />
          <Route
            exact
            path="/News/Sena-and-Vinedo-Chadwick–Chile-is-Cult-Wines"
            component={New6}
          />
          <Route
            exact
            path="/News/Tributo-a-Steven-Spurrier"
            component={New7}
          />
          <Route
            exact
            path="/News/vfch-naples-children-2022"
            component={New8}
          />
          <Route
            exact
            path="/News/the-family-library-collection-historical-2009-vintage-release"
            component={New9}
          />
          {/* <Route exact path="/News-draf" component={NewsHomeDraf} /> */}
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
